import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <img src={logo} className="App-logo" alt="logo" />
      <div className="App-caption">
        <h1>Sight.</h1>
        <p>See you on September 1st.</p>
      </div>
    </div>
  );
}

export default App;
